import '../assets/style/About.scss'
import Map from '../component/Map'
import IndexFooter from '../component/IndexFooter'
import { useViewport } from '../hooks/useViewport'


export default function About () {
    const { width } = useViewport()

    // const business = [
    //     {
    //         title: '经营理念',
    //         desc: '全従業員の物心両面の幸福を追求するとともに、不動産ビジネスを通じてお客様に安心と信頼される独自のサービスを提供し、地域と共に発展し続ける誠実企業を目指します。'
    //     },
    //     {
    //         title: '信頼・安心・便利',
    //         desc: '私たちが築きたいもの、それはお客様との信頼です。私たちの喜び、それはお客様と共に安心できることです。私たちが目指すもの、各顧客のお住まいの便利となることです。'
    //     },
    //     {
    //         title: '事業理念',
    //         desc: '賃貸事業が当社の中心軸であることを全社員が強く意識します。関西における不動産業のモデル企業を目指します。全社員が、不動産モデル企業であるとの自覚をもって業務にあたります。全社員が人格の向上に努めます。地域社会に貢献し、尊敬される企業を目指します。社員が育つ企業、社員が生き生きと仕事をする会社を目指します。'
    //     }
    // ]

    return <div className={`About ${width < 481 && 'AboutMobile'}`}>
        <div className='layout'>
        <div className="About-banner">
            <img src={require("../assets/images/img/aboutBanner.png")} alt='' />
        </div>
        <div className="About-content">
            <div className="company-business">
                <div className="item-title">
                    <div className="title">会社理念</div>
                    {/* <div className="subtitle">シェアプレイス聖蹟桜ヶ丘</div> */}
                    <div className="line"></div>
                </div>
                <div className="business-info">
                    <div className="business-item">
                        <div className="item-title">经营理念</div>
                        <div className="item-desc">全従業員の物心両面の幸福を追求するとともに、不動産ビジネスを通じてお客様に安心と信頼される独自のサービスを提供し、地域と共に発展し続ける誠実企業を目指します。</div>
                    </div>
                    <div className="business-item">
                        <div className="item-title">信頼・安心・便利</div>
                        <div className="item-desc">私たちが築きたいもの、それはお客様との<span>信頼</span>です。私たちの喜び、それはお客様と共に<span>安心</span>できることです。私たちが目指すもの、各顧客のお住まいの<span>便利</span>となることです。</div>
                    </div>
                    <div className="business-item">
                        <div className="item-title">事業理念</div>
                        <div className="item-desc">賃貸事業が当社の中心軸であることを全社員が強く意識します。不動産業のモデル企業を目指します。全社員が、不動産モデル企業であるとの自覚をもって業務にあたります。全社員が人格の向上に努めます。地域社会に貢献し、尊敬される企業を目指します。社員が育つ企業、社員が生き生きと仕事をする会社を目指します。</div>
                    </div>
                </div>
            </div>
            <div className="about-company">
                <div className="company-title item-title">
                    <div className="title">会社案内</div>
                    {/* <div className="subtitle">シェアプレイス聖蹟桜ヶ丘</div> */}
                    <div className="line"></div>
                </div>
                <div className="company-content">
                    <div className="content-image">
                        <img src={require('../assets/images/img/house.png')} alt="" />
                    </div>
                    <div className="content-info">
                        <div className="info-title">
                            <p className="title">ハッピーハウジング 株式会社</p>
                            <img src={require('../assets/images/icon/title-bg3.png')} alt="" />
                        </div>
                        <div className="info-desc">
                            <div className="desc-item">
                                <p className="item-left">资本金</p>
                                <p className="item-right">8,000万円</p>
                            </div>
                            <div className="desc-item">
                                <p className="item-left">住所</p>
                                <div className="item-right">
                                    <p>〒101-0051　東京都千代田区神田神保町3-2　</p>
                                    <p>アオキビスポークビル7F</p>
                                </div>
                                
                            </div>
                            <div className="desc-item">
                                <p className="item-left">交通</p>
                                <div className="item-right">
                                    <p >JR中央・総武線 徒歩９分  </p>
                                    <p >地下鉄：半蔵門線・ 東西線・</p>
                                    <p>都営新宿線・都営三田線 徒歩３分</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="about-map">
                <div className="map-title">
                    <p className="title">所在地地図</p>
                    {/* <p className="subtitle">シェアプレイス聖蹟桜ヶ丘</p> */}
                    <div className="line"></div>
                </div>
                <div className="map-content">
                    <Map 
                        dataLang="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d352.0633698099388!2d139.75447659260493!3d35.69595457180068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c14894fa601%3A0x7c250456c1bfadbe!2z5pel5pys44CB44CSMTAxLTAwNTEg5p2x5Lqs6YO95Y2D5Luj55Sw5Yy656We55Sw56We5L-d55S677yT5LiB55uu77yS!5e0!3m2!1sja!2s!4v1711702766029!5m2!1sja!2s" 
                        width={'14.4rem'}
                        height={'5rem'}
                    />
                    <div className='text'>
                        <img src={require("../assets/images/icon/icon-address.png")} alt="" />
                        <p className="address">〒101-0051　東京都千代田区神田神保町3-2　アオキビスポークビル7F</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className="about-footer">
            <IndexFooter />
        </div>
    </div>
}


