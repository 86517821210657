


export default function CloseIcon () {


    return <div className="CloseIcon">
        <img src={require('../assets/images/icon/close-icon.png')} alt="" />
    </div>
} 


