import Map from "../Map";
import { useViewport } from "../../hooks/useViewport";

export default function BasicMap (params) {
    const { data } = params
    const { width } = useViewport()

    return <div className="BasicMap tabs-item">
        <Map 
            dataLang={data.map}
            width={`${width > 480 ? '7.95rem' : "21.625rem"}`}
            height={`${width > 480 ? '4.9rem' : "12.375rem"}`}
        />
        <div className="map-address">
            <div className="address-icon">
                <img src={require("../../assets/images/icon/icon-address.png")} alt="" />
            </div>
            <p className="address-text">{data.address}</p>
        </div>
    </div>
}


