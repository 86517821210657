import { useEffect, useState } from "react"
import {LeftOutlined,RightOutlined,FullscreenOutlined} from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { useViewport } from "../../hooks/useViewport"


export default function Around (params) {
    const { data, id, smallImageList } = params
    const navigateTo = useNavigate()
    const { width } = useViewport()
 
    let [groupImage,setGroupImage] = useState(1)
    // 图片列表移动距离
    let [moveLength,setMoveLength] = useState(0) 
    // 页码列表
    let [pagelist,setPageList] = useState([])

    const checkPage = (e) => {
        if (e !== moveLength) {
            setMoveLength(e)
        }
    } 
 
    useEffect(()=>{
        let page = Math.ceil(smallImageList.length / 6)
        let pageArr = []
        for (let i = 0; i < page; i++) {
            pageArr.push(i);
        }
        setPageList([...pageArr])
    },[smallImageList,groupImage])



    return <div className="Around">
        <div className="large-image-box">
            <div className="large-image-list" style={{ transform: `translateX(-${moveLength * 100}%)` }}>
                {
                    data?.map((e,i)=>(
                        <div className="item-box" key={i}>
                            {
                                e.map((ele,index) => (
                                    <div className="image-item" key={index}>
                                        {
                                            ele && <img src={`${process.env.REACT_APP_API_URL}/${ele.image}`} alt="" onClick={()=>navigateTo(`/ImagePreview?environment&id=${id}`)} />
                                        }
                                        {
                                            ele && <p className="image-desc">{ele.description}</p>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
        <div className="small-image-box">
            <div className="small-image-list">
                {
                    pagelist?.map((e,i)=>(
                        <div className="small-image-item" key={i} onClick={()=>checkPage(e)}>
                            {/* {
                                e && <img src={`${process.env.REACT_APP_API_URL}/${e.image}`} alt="" />
                            } */}
                            <div className={`page-item ${moveLength === e && 'page-item-active'} ${pagelist.length === 1 && 'noPage-item'}`} ></div>
                        </div>
                    ))
                }
            </div>
        </div>
        {/* <div className="image-btn">
            <div className={`prev-btn btn ${prevDisabled ? '' : 'bgBlue'}`} onClick={()=>prevHandle()}>
                <LeftOutlined />
            </div>
            <div className={`next-btn btn ${nextDisabled ? '' : "bgBlue"}`} onClick={()=>nextHandle()}>
                <RightOutlined />
            </div>
        </div> */}
    </div>
}

