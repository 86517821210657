import { useEffect } from "react";


export default function BasicData (params) {

    const { data, name } = params;

    return <div className="BasicData tabs-item">
        <div className="name basic-item">
            <p className="title">物件名</p>
            <p className="content">{name}</p>
        </div>
        <div className="address basic-item">
            <p className="title">所在地</p>
            <p className="content">{data?.location}</p>
        </div>
        <div className="navigation basic-item">
            <div className="title">アクセス</div>
            <div className="content">{data.navigation}</div>
        </div>
        <div className="rental basic-item">
            <div className="title">賃料</div>
            <div className="content">{data.rentalStart} ～ {data.rentalEnd}円</div>
        </div>
        <div className="area basic-item">
            <div className="title">広さ</div>
            <div className="content">{data.acreageStart} ～ {data.acreageEnd} ㎡</div>
        </div>
        <div className="rooms basic-item">
            <div className="title">総室数</div>
            <div className="content">{data.roomNum}室</div>
        </div>
        <div className="tenant basic-item">
            <div className="title">備考</div>
            <div className="content" dangerouslySetInnerHTML={{__html: data.tenementData}}></div>
        </div>
    </div>
}

