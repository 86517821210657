import { Dropdown } from 'antd'
import { useViewport } from '../hooks/useViewport'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function IndexFooter () {
    const { width } = useViewport()
    const navigateTo = useNavigate()

    let [codeOpen,setCodeOpen] = useState(false)
    let [codeClick,setCodeClick] = useState(false)


    const items = [
        {
            key: "1",
            label: (
                <div className="wechatCode" onClick={()=>imgClick()}>
                    <img 
                        src={require("../assets/images/img/img-redBook-code.png")} 
                    />
                    <div className="tip" style={{fontSize: '.14rem', color: '#9FAEC2', marginTop: '.11rem', display: 'flex', alignItems: 'center'}}>
                        <img className='img-instagram' src={require("../assets/images/img/img-redBook.png")} alt="" />
                        <span className='tip-text' style={{lineHeight: '.18rem'}}>賃貸物件情報</span>
                    </div>
                </div>
            )
        }
    ]

    const imgClick = () => {
        setCodeOpen(false);
        setCodeClick(false)
    }

    const handleMouseEnter = () => {
        if (!codeClick) {
            setCodeOpen(true)
        }
    }

    const handleMouseLeave = () => {
        if (!codeClick) {
            setCodeOpen(false)
        }
    }

    const handleClick = () => {
        if (!codeClick && codeOpen) {
            setCodeOpen(true)
            setCodeClick(true)
        }else{
            codeOpen = !codeOpen;
            setCodeOpen(codeOpen);
            codeClick = !codeClick;
            setCodeClick(codeClick)
        }
    }

    return <div className={`layout ${width < 481 && 'footerMobileLayout'}`}>
        <div className='footer-img'>
            <img src={require('../assets/images/icon/index-footer-logo.png')} alt=''/>
        </div>
        <div className='footer-content'>
            <div className="footer-flex">
                <div className="flex-item">
                    <p className='footer-title'>ハッピーハウジング株式会社</p>
                    <div className='footer-desc'>
                        <div className={`desc ${width < 481 && 'descMobile'}`}>
                            <p className='desc-text'>〒101-0051 東京都千代田区神田神保町3-2</p>
                            <p className='desc-text'>アオキビスポークビル7F</p>
                        </div>
                    </div>
                </div>
                <div className="flex-item">
                    <div className='footer-desc'>
                        <div className='desc'>
                            <a className='desc-text desc-link' href={`/Statement?TermsOfService`} >ご利用規約 </a>
                            <a className='desc-text desc-link' href='/Statement?PersonalInformationProtection'>個人情報保護方針</a>
                            <a className='desc-text desc-link' href='/Statement?PrivacyPolicy'>プライバシーポリシー</a>
                            <a className='desc-text desc-link' href='/Advisory'>内览予约</a>
                        </div>
                    </div>
                </div>
                <div className="flex-item">
                    <div className='footer-desc'>
                        <div className='desc'>
                            <div className='desc-text img-desc'>
                                <img className='img-instagram' src={require('../assets/images/img/img-instagran.png')} alt="" />
                                <span>instagram</span>
                            </div>
                            {
                                width > 480 ? <div className='desc-text img-desc'>
                                    <Dropdown
                                        menu={{
                                            items
                                        }}
                                        arrow
                                        placement="top"
                                        open={codeOpen}
                                        getPopupContainer={triggerNode => triggerNode.parentNode}
                                    >
                                        <a 
                                            className="redBook"
                                            onMouseEnter={()=>handleMouseEnter()}
                                            onMouseLeave={()=>handleMouseLeave()}
                                            onClick={()=>handleClick()}
                                        >
                                            <img className='img-instagram' src={require("../assets/images/img/img-redBook.png")} alt="" />
                                            <span>小红书</span>
                                        </a>
                                    </Dropdown>
                                </div>
                                : 
                                <a className='desc-text img-desc' href='https://www.xiaohongshu.com/user/profile/59d5656c6eea88266e1bf8d8?xhsshare=WeixinSession&appuid=59d5656c6eea88266e1bf8d8&apptime=1702887299&wechatWid=7ca7d36166507fbf2d6c17c1551b1682&wechatOrigin=menu'>
                                    <img className='img-instagram' src={require('../assets/images/img/img-redBook.png')} alt="" />
                                    <span>小红书</span>
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}


