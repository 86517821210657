

export default function Map (params) {
    const { dataLang, width, height } = params

    return <iframe
        title="map"
        src={dataLang}
        // width={width}
        // height={height} 
        style={{border: "none", width:width, height: height}}
        loading="lazy" 
    ></iframe>
}

