import Index from "../view/Index";
import Detail from "../view/Detail";
import About from "../view/About";
import ImagePreview from "../view/ImagePreview";
import Advisory from "../view/Advisory";
import Statement from "../view/Statement";

const routes = [
    { 
        path: "/",
        element: <Index />,
    },
    {
        path: "/Detail",
        element: <Detail />
    },
    {
        path: "/About",
        element: <About />
    },
    {
        path: "/ImagePreview",
        element: <ImagePreview />
    },
    {
        path: "/Advisory",
        element: <Advisory />
    },
    {
        path: "/Statement",
        element: <Statement />
    }
]

export default routes
