import "../assets/style/Statement.scss"
import IndexFooter from "../component/IndexFooter"
import TermsOfService from "../component/Statement/TermsOfService"
import PersonalInformationProtection from "../component/Statement/PersonalInformationProtection"
import PrivacyPolicy from "../component/Statement/PrivacyPolicy"
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import { useViewport } from "../hooks/useViewport";

export default function Statement () {

    const location = useLocation();
    const { width } = useViewport();

    let [type,setType] = useState()


    useEffect(()=>{
        let search = location.search.split('?')[1];
        setType(search)
    },[location])

    return <div className={`Statement ${width < 481 && 'StatementMobile'}`}>
        <div className="content">
            { type === 'PrivacyPolicy' ? <PrivacyPolicy /> : type === 'TermsOfService' ? <TermsOfService /> : type === 'PersonalInformationProtection' ? <PersonalInformationProtection /> : ''}
        </div>
        <div className="detail-footer">
            <IndexFooter />
        </div>
    </div>
}


