


export default  function ListDataDity (params) {
    const { data, type } = params
    const DataDity = () => {
        let info
        if (type==="index") {
            info = []
            data.map((e)=>{
                let imgList = e.images.split(',');
                let advantageArr = []
                if (e.info.advantages) {
                    advantageArr = e.info.advantages.split(",");
                }
                let obj = {
                    id: e.id,
                    name: e.name,
                    location: e.info.location,
                    navigation: e.info.navigation,
                    image: imgList[0],
                    advantages: advantageArr,
                    status: e.status
                }
                info.push(obj)
            })
        }
        if (type==="Detail") {
            info = {}
            let rentalStart = data.info.rental.split(",")[0].toLocaleString();
            let rentalEnd = data.info.rental.split(",")[1].toLocaleString();
            rentalEnd = Number(rentalEnd)
            rentalStart = Number(rentalStart)
            rentalStart = rentalStart.toLocaleString()
            rentalEnd = rentalEnd.toLocaleString()
            let acreageStart = data.info.acreage.split("-")[0];
            let acreageEnd = data.info.acreage.split("-")[1]
            let surroundingsList = []
            if (data.info.surroundings) {
                surroundingsList = JSON.parse(data.info.surroundings)
            }
            let advantageArr = []
            if (data.info.advantages) {
                advantageArr = data.info.advantages.split(",");
            }
            info = {
                id: data.id,
                name: data.name,
                images: data.images.split(','),
                description: data.description,
                basicData: {
                    location: data.info.location,
                    navigation: data.info.navigation,
                    rentalStart,
                    rentalEnd,
                    acreageEnd,
                    acreageStart,
                    tenementData: data.info.tenementData,
                    roomNum: data.info.roomNum
                },
                map: data.info.map,
                surroundings: surroundingsList,
                advantages: advantageArr,
            }


        }
        return info;

    }

    return (DataDity())

}


