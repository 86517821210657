


export default function TermsOfService () {
    

    return <div className="TermsOfService statement-item">
        <div className="statement-title">
            <p className="title-text">ご利用規約</p>
        </div>
        <div className="statement-content">
            <p className="content-text">https://hhmp.co（以下、当サイトという）は、ハッピーハウジング 株式会社以下は、「当社」という）が、当サイト利用者（以下、サイト利用者という）に対して、当社提携企業（以下、「提携企業」という）に対する資料請求依頼、問い合わせ依頼サービスを提供いたします。（以下、「本サービス」という）</p>
            <p className="content-text">サイト利用者は、本利用規約（以下、「本規約」という）をお読みの上、当サイトをご利用下さい。</p>
            <p className="content-text paragraph emphasize">当サイトを利用される方は、本規約に同意したものと見なします。</p>
            <p className="content-text">第１条（本規約の適用）当社は、サイト利用者に対し、本サービスを本規約に基づき提供します。</p>
            <p className="content-text paragraph">サイト利用者は、本サービスの利用にあたり、本規約の内容を理解し、これを遵守するものとします。</p>
            <p className="content-text">第２条（本規約の変更）当社は、サイト利用者の承諾を得ることなく本規約を変更することができるものとし、サイト利用者は変更後の利用規約に従うものとします。</p>
            <p className="content-text paragraph">変更された利用規約は、当社が当サイトに変更後の利用規約を掲載した時点より、効力が発生するものとします。</p>
            <p className="content-text paragraph">第３条（サービスの内容）当サイトにて提供されるサービスは、サイト利用者が入力した情報を、提携企業に送信し、資料請求、見積もり依頼、問い合わせ依頼をするサービスです。</p>
            <p className="content-text paragraph">第４条（著作権）当サイトに掲載されている、全ての著作物の著作権は、当社に帰属するものとします。当社の承諾なく、これらの著作物の一部または全部を利用することは出来ません。</p>
            <p className="content-text ">第５条（契約）契約の締結は、サイト利用者と提携企業間で直接行います。</p>
            <p className="content-text ">当社は、サイト利用者と提携企業間における契約に一切関与いたしません。また、サイト利用者および提携企業に対する、契約の媒介を行いません。</p>
            <p className="content-text paragraph">サイト利用者と提携企業の間で行われる商談および契約に関して、当社は一切責任を負いません。</p>
            <p className="content-text paragraph">第６条（中断・廃止）当社は、サイト利用者の承諾を得ることなく、本サービスを中断および廃止できるものとします。</p>
            <p className="content-text paragraph">第７条（個人情報の扱い）当サイトにおける個人情報は、別途掲載の個人情報保護方針に基づき利用および管理致します。</p>
            <p className="content-text">第８条（免責事項）当社は、サイト利用者が、当サイトの利用に際して損害を被った際でも、当社は一切の責任を負いません。利用者は自己の責任において本サービスを利用するものとします。</p>
            <p className="content-text">当社は、個人情報保護方針に基づき個人情報の取り扱い、および当サイトの運営に努めますが、万一個人情報の漏洩が発生し、利用者に損害を与えた場合でも、当社はその責任を負いません。</p>
            <p className="content-text paragraph">サイト利用者と、提携企業とのやり取りにおける一切の事柄おいて、当社は一切責任を負いません。</p>
            <p className="content-text">第９条（禁止行為）当サイトの利用において、サイト利用者が以下の行為を行うことを禁止します。</p>
            <ul>
                <li className="content-text">・本サービスに関する情報を改ざんする行為</li>
                <li className="content-text">・利用者以外の者になりすまして本サービスを利用する行為</li>
                <li className="content-text">・有害なコンピュータプログラム等を送信又は書き込む行為</li>
                <li className="content-text">・第三者又は当社の財産、名誉及びプライバシー等を侵害する行為</li>
                <li className="content-text">・本人の同意を得ることなく又は詐欺的な手段により第三者又は当社の個人情報を収集する行為</li>
                <li className="content-text">・本サービスの利用又は提供を妨げる行為</li>
                <li className="content-text">・第三者又は当社の著作権その他の知的財産権を侵害する行為</li>
                <li className="content-text">・法令又は公序良俗に反する行為</li>
                <li className="content-text">・本サービスを利用した営業活動その他営利を目的とする行為</li>
                <li className="content-text">・当社の信用を傷つけ、又は当社に損害を与える行為</li>
                <li className="content-text paragraph">・その他、当社が不適切と判断した行為</li>
            </ul>
            <p className="content-text">第１０条（準拠法・管轄）本規約の効力、解釈及び履行については、日本法に準拠するものとします。</p>
            <p className="content-text paragraph">当規約に記述のない事項に関して生じた紛争は、東京地方裁判所を合意管轄裁判所とするものとします。</p>
            <p className="content-text">第１１条（その他）本規約に定めのない事項が生じたときは、協議のうえ円満に解決を図るものとします。</p>
            <p className="footer-text">制定日：令和5年12月1日</p>
        </div>
    </div>
}

