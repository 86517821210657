import { Select } from 'antd'
import "../assets/style/Detail.scss"
import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useViewport } from '../hooks/useViewport'
import { UpOutlined, DownOutlined } from "@ant-design/icons"


export default function AppHeader () {
    const navigateTo = useNavigate()
    const location = useLocation()
    const { width } = useViewport()

    let [showMenu,setShowMenu] = useState(false)
    let [checkLang,setCheckLang] = useState("EN")
    let [anchor,setAnchor] = useState(sessionStorage.getItem('anchorPoint'))
    let [showLang,setShowLang] = useState(false)

    const headerList = [
        // {
        //     en: "Magazine",
        //     jn: "マガジン",
        //     url: "/"
        // },
        {
            en: "Rental property",
            jn: "賃貸物件",
            url: "",
            className: 'Index-thing'
        },
        {
            en: "About us",
            jn: "私たちについて",
            url: "/About",
            className: ''
        },
        // {
        //     en: "Contact",
        //     jn: "コンタクト"
        // },
    ]

    const showList = () => {
        showMenu = !showMenu;
        setShowMenu(showMenu)
    }

    const showLanguage = () => {
        showLang = !showLang;
        setShowLang(showLang)
    }

    const changeLang = (val) => {
        checkLang = val;
        setCheckLang(checkLang)
        setShowLang(false)
    }

    const linkClick = (type,data) => {
        let path = location.pathname.split('/')[1];
        if (path !== type) {
            navigateTo(`${type}`)
            let oldAnchorPoint = sessionStorage.getItem('anchorPoint');
            if (oldAnchorPoint === data) {
            sessionStorage.removeItem('anchorPoint')
            sessionStorage.setItem('anchorPoint',data)
            }else{
            sessionStorage.setItem('anchorPoint',data)
            }
            window.dispatchEvent(new Event("setanch"))
        }else{
            sessionStorage.setItem('anchorPoint','Index-thing-anchor')
            window.dispatchEvent(new Event("setanch"))
        } 
      }

    const langCol = [
        {
            value: "en",
            label: "EN",
        },
        {
            value: "jn",
            label: "日语"
        },
        {
            value: "zh",
            label: "中文"
        }
    ]

    function preventDefault(event) {
        event.preventDefault();
      }

    useEffect(()=>{
        setShowMenu(false)
        let path = location.pathname;
        if (path === "/") {
            document.getElementsByClassName("AppHeader")[0].style.backgroundColor = "#E9F6FF"
        }else{
            document.getElementsByClassName("AppHeader")[0].style.backgroundColor = "white"
        }
    },[location])

    useEffect(()=>{
        window.addEventListener('setanch',()=>{
            if (sessionStorage.getItem('anchorPoint')) {
                anchor = sessionStorage.getItem('anchorPoint')
                setAnchor(anchor)
            }
        })
        return window.addEventListener('setanch',()=>{
            if (sessionStorage.getItem('anchorPoint')) {
                anchor = sessionStorage.getItem('anchorPoint')
                setAnchor(anchor)
            }
        })
    },[])

    useEffect(()=>{
        if (showMenu) {
            document.body.style.overflow = 'hidden';
            document.getElementsByClassName("AppHeader")[0].style.backgroundColor = "white"
            // document.addEventListener('touchmove', preventDefault, { passive: false });
        }else{
            document.body.style.overflow = 'auto';
            if (location.pathname === '/') {
                document.getElementsByClassName("AppHeader")[0].style.backgroundColor = "#E9F6FF"
            }
            // document.removeEventListener('touchmove', preventDefault, { passive: true });
        }
    },[showMenu])

    return <div className="AppHeader">
            {
                (!showMenu && width < 481) ? <img className='mobileIcon' src={require('../assets/images/icon/header-logo.png')} onClick={()=>navigateTo('/')} alt=''/>
                :
                width < 481 ? <div style={{width: '1px', height: '1px'}}></div> : ""
            }
        <div className={`header-left ${showMenu && 'show-header-left'}`} >
            <img src={require('../assets/images/icon/header-logo.png')} onClick={()=>navigateTo('/')} alt=''/>
            <div className={`header-list `}>
                {
                    headerList.map((e,i)=>{
                        return <div className='header-li' key={i} onClick={()=>linkClick(e.url,e.className)}>
                            <p className='li-top'>{e.en}</p>
                            <p className='li-bottom'>{e.jn}</p>
                        </div>
                    })
                }
                {/* {
                    width < 481 && <div className='language'>
                        <Select options={langCol} value={'en'} open
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                        />
                    </div>
                } */}
                {/* {
                    width < 481 && <div className={`header-language ${showLang && 'header-language-active'}`}>
                        <div className='lang-text' onClick={()=>showLanguage()}>
                            {checkLang}
                            {
                                showLang ? <UpOutlined /> : <DownOutlined />
                            }
                        </div>
                        <div className='lang-list'>
                            <p className='lang-li' onClick={()=>changeLang("EN")}>EN</p>
                            <p className='lang-li' onClick={()=>changeLang("日语")}>日语</p>
                            <p className='lang-li' onClick={()=>changeLang("中文")}>中文</p>
                        </div>
                    </div>
                } */}
            </div>
        </div>
        {
            width < 481 ? showMenu ? <img className='menu-icon' onClick={()=>showList()} src={require("../assets/images/icon/close-icon-mobile.png")} alt='' /> 
            : <img className='menu-icon' onClick={()=>showList()} src={require("../assets/images/icon/menu-icon.png")} alt='' />
            : ""
        }
        {/* {
            width > 480 && <div className='language'>
                <Select options={langCol} value={'en'} />
            </div>
        } */}
    </div>
}

