import { Carousel, Tabs, Input, Select, Button } from "antd"
import {CheckOutlined, FullscreenOutlined} from "@ant-design/icons"
import { useEffect, useState, useRef } from "react"
import BasicData from "../component/Detail/BasicData";
import BasicMap from "../component/Detail/BasicMap";
import Around from "../component/Detail/Around";
import DeviceInformation from "../component/Detail/DeviceInformation";
import SpecificData from "../component/Detail/SpecificData";
import {LeftOutlined,RightOutlined,DownOutlined,UpOutlined, DoubleRightOutlined} from "@ant-design/icons"
import RemarkComponent from "../component/RemarkComponent";
import IndexFooter from "../component/IndexFooter";
import { getPropertyInfo, getRoomInfo } from "../request/api";
import { useLocation, useNavigate } from "react-router-dom";
import ListDataDity from '../utils/ListDataDity'
import BasicBtn from "../component/Detail/BasicBtn";
import { useViewport } from "../hooks/useViewport";
// import Panorama from "../component/Detail/Panorama";
import EquipmentAndDetail from "../component/Detail/EquipmentAndDetail";



export default function Detail () {

    const location = useLocation()
    const navigateTo = useNavigate()
    const { width } = useViewport()


    let [checkTabs,setCheckTabs] = useState(1)
    let [showAll,setShowAll] = useState(false)
    // 房间图片列表移动距离
    let [roomImageMoveLength,setRoomImageMoveLength] = useState(0)
    // 物件概要是否溢出隐藏
    let [descOverflow,setDescOverflow] = useState(true)
    // 物业详情信息
    let [propertyInfo,setPropertyInfo] = useState({})
    // 基本情报信息
    let [basicData,setBasicData] = useState({})
    // 地图信息
    let [mapData,setMapData] = useState({})
    // 周边环境
    let [surroundings,setSurroundings] = useState([])
    // 周边环境小图
    let [smallSurroundings,setSmallSurroundings] = useState([])
    // 设备信息
    let [equipment,setEquipment] = useState({})
    // 房间信息
    let [roomInfo,setRoomInfo] = useState([])
    // 房间图片列表
    let [roomImages,setRoomImages] = useState([])
    // 选择的房间
    let [checkRoom,setCheckRoom] = useState()
    // 房间楼层
    let [roomFloor,setRoomFloor] = useState() 
    // 选择的房间状态
    let [roomStatus,setRoomStatus] = useState()
    // 物业图片列表
    let [propertyImageList,setPropertyImageList] = useState([])
    // 图片滚动元素
    const propertyImgRef = useRef(null)
    // 选择的tab
    const [checkTab,setCheckTab] = useState(1)
    // 房间户型图
    let [roomLayout,setRoomLayout] = useState()
    // 房间面积
    let [roomArea,setRoomArea] = useState()
    // 房间租金
    let [roomRent,setRoomRent] = useState()
    // 房间描述
    let [roomDesc,setRoomDesc] = useState()
    // 房间图片移动端滑动距离
    let [touchStartPosition, setTouchStartPosition] = useState()
    let [delta,setDelta] = useState()
    // 渲染到页面上的物业图片列表
    let [showPropertyImageList,setShowPropertyImageList] = useState([]);
    let [advantageList,setAdvantageList] = useState([])
    // 设备状态列表
    let [iconList,setIconList] = useState([
        {
            name: '新改装',
            icon: 'newlyRenovated.png',
            activeIcon: 'NewlyRenovatedActive.png',
            isSelected: false
        },
        {
            name: '2階以上の物件',
            icon: 'highFloor.png',
            activeIcon: 'highFloorActive.png',
            isSelected: false
        },
        {
            name: '南向き',
            icon: 'facingSouth.png',
            activeIcon: 'facingSouthActive.png',
            isSelected: false
        },
        {
            name: '駐車場あり',
            icon: 'parkingLot.png',
            activeIcon: 'parkingLotActive.png',
            isSelected: false
        },
        {
            name: 'オートロック',
            icon: 'autoLock.png',
            activeIcon: 'autoLockActive.png',
            isSelected: false
        },
        {
            name: '冷蔵庫',
            icon: 'refrigerator.png',
            activeIcon: 'refrigeratorActive.png',
            isSelected: false
        },
        {
            name: '電子レンジ',
            icon: 'MicroWaveOven.png',
            activeIcon: 'MicroWaveOvenActive.png',
            isSelected: false
        },
        {
            name: 'エアコン',
            icon: 'airConditioner.png',
            activeIcon: 'airConditionerActive.png',
            isSelected: false
        },
        {
            name: 'バス・トイレ別',
            icon: 'SeparateBathroomAndToilet.png',
            activeIcon: 'SeparateBathroomAndToiletActive.png',
            isSelected: false
        },
        {
            name: '高速インターネット',
            icon: 'TrackingFunction.png',
            activeIcon: 'TrackingFunctionActive.png',
            isSelected: false
        },
        {
            name: 'フローリング',
            icon: 'woodenFloor.png',
            activeIcon: 'woodenFloorActive.png',
            isSelected: false
        },
        {
            name: 'ペット相談可',
            icon: 'petConsultation.png',
            activeIcon: 'petConsultationActive.png',
            isSelected: false
        },
        {
            name: '机椅子',
            icon: 'tablesAndChairs.png',
            activeIcon: 'tablesAndChairsActive.png',
            isSelected: false
        },
        {
            name: '洗濯機',
            icon: 'washingMachine.png',
            activeIcon: 'washingMachineActive.png',
            isSelected: false
        },
    ])
    // 详情信息
    let [detailInfo,setDetailInfo] = useState({})
    // 展示设备列表
    let [showEquipmentList,setShowEquipmentList] = useState([
        {
            name: '新改装',
            icon: 'newlyRenovatedWhite.png',
            isSelected: false
        },
        {
            name: '2階以上の物件',
            icon: 'highFloorWhite.png',
            isSelected: false
        },
        {
            name: '南向き',
            icon: 'facingSouthWhite.png',
            isSelected: false
        },
        {
            name: '駐車場あり',
            icon: 'parkingLotWhite.png',
            isSelected: false
        },
        {
            name: 'オートロック',
            icon: 'autoLockWhite.png',
            isSelected: false
        },
        {
            name: '冷蔵庫',
            icon: 'refrigeratorWhite.png',
            isSelected: false
        },
        {
            name: '電子レンジ',
            icon: 'MicroWaveOvenWhite.png',
            isSelected: false
        },
        {
            name: 'エアコン',
            icon: 'airConditionerWhite.png',
            isSelected: false
        },
        {
            name: 'バス・トイレ別',
            icon: 'SeparateBathroomAndToiletWhite.png',
            isSelected: false
        },
        {
            name: '高速インターネット',
            icon: 'TrackingFunctionWhite.png',
            isSelected: false
        },
        {
            name: 'フローリング',
            icon: 'woodenFloorWhite.png',
            isSelected: false
        },
        {
            name: 'ペット相談可',
            icon: 'petConsultationWhite.png',
            isSelected: false
        },
        {
            name: '机椅子',
            icon: 'tablesAndChairsWhite.png',
            isSelected: false
        },
        {
            name: '洗濯機',
            icon: 'washingMachineWhite.png',
            isSelected: false
        },
    ]) 
    let [roomId,setRoomId] = useState()

    const bannerList = [
        1,
        2,
        3,
        4
    ]


    const settings = {
        // infinite: true,
        // centerMode: true,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        // centerPadding: '9%',
    };

    const tabsChange = (key) => {
        setCheckTabs(key)
    }

    const roomImagePrev = () => {
        if (roomImageMoveLength >= 1) {
            roomImageMoveLength -= 1;
            setRoomImageMoveLength(roomImageMoveLength)
        }
    }

    const roomImageNext = () => {
        let maxNum = roomImages.length 
        if (roomImageMoveLength < maxNum - 1) {
            roomImageMoveLength += 1;
            setRoomImageMoveLength(roomImageMoveLength)
        }
    }

    // 处理周边环境大图片数组
    const splitGroup = (array, size) => {
        let result = []
        let group = (arr) => {
          let list = arr.slice(0, size)
          let rest = arr.slice(size, arr.length)
          result.push(list)
          if (arr.length <= size) {
            return result
          } else {
            return group(rest)
          }
        }
        return group(array)
    }

    const getData = async (id) => {
        if (id) {
            await getPropertyInfo({
                id: id
            })
            .then((res) => {
                if(res.code === 0) {
                    console.log("getData====>",res);
                    let obj = ListDataDity({
                        data: res.data,
                        type: "Detail"
                    });
                    basicData = obj.basicData;
                    mapData = {
                        address: obj.basicData.location,
                        map: obj.map
                    }
                    let str = res.data.info.equipStatus
                    if (str) {
                        let arr = str.split(',')
                        let list = iconList;
                        let showList = showEquipmentList
                        arr.map((e,i)=>{
                            if (e === '1') {
                                list[i].isSelected = true;
                                showList[i].isSelected = true
                            }
                        })
                        setIconList([...list])
                        setShowEquipmentList([...showList])
                    }
                    detailInfo.equipment = res.data.info.equipments;
                    detailInfo.contract = res.data.info.contractPeriod;
                    detailInfo.extradition = res.data.info.extradition;
                    detailInfo.statusQuo = res.data.info.currentStatus;
                    setDetailInfo({...detailInfo})
                    let list = splitGroup(obj.surroundings,6)
                    surroundings = list;
                    setSurroundings([...surroundings])
                    setSmallSurroundings([...obj.surroundings])
                    setMapData({...mapData})
                    setBasicData({...basicData})
                    propertyImageList = obj.images;
                    setPropertyImageList([...propertyImageList])
                    propertyInfo = obj;
                    setPropertyInfo({...propertyInfo});
                    advantageList = obj.advantages;
                    setAdvantageList([...advantageList])
                }
            }).catch((err) => {
                
            });
        }
    }

    const getRoomImage = async (id) => {
        if (id) {
            await getRoomInfo({
                id: id
            })
            .then((res) => {
                console.log("res====>",res);
                if (res.code === 0 && res.data.list) {
                    let arr = []
                    res.data.list.map((e)=>{
                        arr.push({
                            id: e.id,
                            suiteNO: e.suiteNO,
                            detail: e.detail,
                            description: e.description,
                            status: e.status,
                            rental: e.rental,
                            area: e.area,
                            floor: e.floor,
                            images: e.images.split(","),
                            layout: e.layout
                        })
                    })
                    setCheckRoom(arr[0].suiteNO)
                    setRoomFloor(arr[0].floor)
                    roomInfo = arr;
                    setRoomInfo([...roomInfo])
                }
            }).catch((err) => {
                
            });
        }
    }

    // 选择房间
    const changeRoom = (id) => {
        setCheckRoom(id);
        setRoomImageMoveLength(0);
    }

    const checkTabWrapper = () => {
        switch (checkTab) {
            case 1:
                return <BasicData data={basicData} name={propertyInfo.name} />;
            case 2: 
                return <BasicMap data={mapData} />;
            case 3:
                return <Around data={surroundings} id={propertyInfo.id} smallImageList={smallSurroundings} />;
            case 4: 
                // return <DeviceInformation data={equipment} />
                return <EquipmentAndDetail iconList={iconList} detailInfo={detailInfo} />
            default:
                break;
        }
    }

    const scrollToAnchor = (anchorName) => {
        let anchorElement = document.getElementsByClassName(`${anchorName}`)[0];
        anchorElement.scrollIntoView({behavior: 'smooth', block:'start'})
    }

    const jumpToBeaver = (val) => {
        scrollToAnchor(val)
    }

    useEffect(()=>{
        let id = location.search.split("?")[1]
        getData(id)
        getRoomImage(id)
        setRoomId(id)
    },[location])

    useEffect(()=>{
        roomInfo.map((e)=>{
            if (e.suiteNO === checkRoom) {
                let rental = e.rental.toLocaleString()
                roomStatus = e.status
                roomImages = e.images;
                roomFloor = e.floor;
                roomLayout = e.layout;
                setRoomFloor(roomFloor)
                setRoomImages([...roomImages])
                setRoomStatus(roomStatus)
                setRoomLayout(roomLayout)
                setRoomRent(rental);
                setRoomArea(e.area)
                setRoomDesc(e.detail)
            }
        })
    },[checkRoom])

    useEffect(()=>{
        if (propertyImageList.length > 0 && propertyImageList.length < 16) {
            let arr = propertyImageList;
            let repeatedArr = []
            if (propertyImageList.length === 1 || propertyImageList.length === 2) {
                repeatedArr = [...propertyImageList,...propertyImageList,...propertyImageList]
            }else{
                repeatedArr = propertyImageList
            }
            repeatedArr.map((e)=>{
                if (arr.length < 16) {
                    arr = [...arr,...repeatedArr]
                }
            })
            let sliceArr = arr.slice(0,8)
            let newArr = [...arr,...sliceArr]
            // arr.map((e,i)=>{
            //     if (i < 8) {
            //         newArr.push(e)
            //     }
            // })
            showPropertyImageList = newArr;
            setShowPropertyImageList([...showPropertyImageList])
        }else if (propertyImageList.length > 16 && propertyImageList % 8 !== 0) {
            let arr = propertyImageList;
            propertyImageList.map((e,i)=>{
                if (arr.length % 8 !== 0) {
                    arr.push(e)
                }
            })
            let newArr = arr;
            arr.map((e,i)=>{
                if (i < 8) {
                    newArr.push(e)
                }
            })
            showPropertyImageList = newArr;
            setShowPropertyImageList([...showPropertyImageList])
        }
    },[propertyImageList])

    const scrollLength = () => {
        const ele = document.getElementsByClassName("detail-image")
        console.log("scrolllenth====>",ele[0].style);
    }
    
    const removeBorder = () => {
        const ele = document.getElementsByClassName("nav-list-li-active")[0];
        const liEle = document.getElementsByClassName("nav-list-li")
        const nextEle = ele.nextSibling
        const prevEle = ele.previousSibling
        for (let i = 0; i < liEle.length; i++) {
            const element = liEle[i];
            // 在这里对每个节点执行所需的操作
            if (width < 481) {
                element.style.borderLeft = '.0625rem solid #329DFF';
            }else{
                element.style.borderLeft = '2px solid #329DFF';
            }
        }
        if (nextEle) {
            nextEle.style.borderLeft = 0
        }
        if (prevEle) {
            prevEle.style.borderRight = 0
        }
    }

    const touchHandle = (e) => {
        touchStartPosition = e.touches[0].clientX
        setTouchStartPosition(touchStartPosition)
    }

    const touchEndHandle = (e) => {
        let maxNum = roomImages.length 
        if(delta >= 60 && roomImageMoveLength >= 1) {
            roomImageMoveLength -= 1;
            setRoomImageMoveLength(roomImageMoveLength)
        }else if (delta <= -60 && roomImageMoveLength < maxNum - 1) {
            roomImageMoveLength += 1;
            setRoomImageMoveLength(roomImageMoveLength)
        }
        setTouchStartPosition(null)
        setDelta(null)
    }

    const handleTouchMove = (e) => {
        if (!touchStartPosition) return;
        const touch = e.touches[0];
        const deltaX = touch.clientX - touchStartPosition;
        delta = deltaX;
        setDelta(delta)
    }

    useEffect(()=>{
        // document.addEventListener("click",removeBorder())
        // return document.removeEventListener("click",removeBorder())
        removeBorder()
    },[checkTab])

    useEffect(()=>{
        let isToRemark = sessionStorage.getItem("toRemark")
        if (isToRemark === location.search.split('?')[1]) {
            scrollToAnchor("detail-remark-title")
            sessionStorage.removeItem("toRemark")
        }
    },[location])


    useEffect(()=>{
        console.log("===>",showEquipmentList);
    },[showEquipmentList])

    return <div className={`Detail ${width < 481 && "DetailMobile"}`}>
        <div className="Detail-content">
            <div className="carousel-container">
                <Carousel {...settings} dots={true} autoplay arrows={true}>
                    {
                        propertyInfo.images?.map((e,i)=>(
                            <div className="carousel-slide" key={i}>
                                <img src={`${process.env.REACT_APP_API_URL}/${e}`} alt="" onClick={()=>navigateTo(`/ImagePreview?estate&id=${propertyInfo.id}`)} />
                                {/* <div className="mask">
                                    <img className="enlarge" onClick={()=>navigateTo(`/ImagePreview?estate&id=${propertyInfo.id}`)} src={require("../assets/images/icon/icon-large.png")} />
                                </div> */}
                                <div className="mask">
                                    <div className="prev"></div>
                                    <div className="next"></div>
                                </div>
                            </div>
                        ))
                    }
                </Carousel>
            </div>
            <div className="banner-slice">
                <img src={require('../assets/images/img/property-slice.png')} alt="" />
            </div>
            
            
            <div className="detail-title">
                <div className="property-title">
                    <p>{propertyInfo.name}</p>
                </div>
                <div className="property-advantage">
                    <ul>
                        {/* {
                            advantageList.map((e,i)=>(
                                <li key={i}>
                                    <div className="advantage-icon"></div>
                                    <p className="advantage-text">{e}</p>
                                </li>
                            ))
                        } */}
                        {
                            showEquipmentList.map((e,i)=>(
                                <li className={`${!e.isSelected && 'hide'}`} key={i}>
                                    <img className="advantage-icon" src={require('../assets/images/property/' + e.icon)} />
                                    <p className="advantage-text">{e.name}</p>
                                </li>
                            ))
                        }
                    </ul>
                    <div className="advantage-line"></div>
                </div>
                {/* <div className="property-commute">
                    <div className="commute-item">
                        <div className="commute-icon">
                            <CheckOutlined />
                        </div>
                        <p className="commute-text">聖蹟桜ヶ丘駅徒歩8分</p>
                    </div>
                    <div className="commute-item">
                        <div className="commute-icon">
                            <CheckOutlined />
                        </div>
                        <p className="commute-text">新宿駅まで特急で26分</p>
                    </div>
                </div> */}
            </div>
            <div className="detail-images-list">
                <div className="detail-image" 
                    style={{
                        width: `${showPropertyImageList.length * 1.8}rem`,
                        animationDuration: `${3 * showPropertyImageList.length}s`
                    }}
                    ref={propertyImgRef}    
                >
                    {
                        showPropertyImageList?.map((e,i)=>(
                            <div className="image-item" key={i}>
                                <img src={`${process.env.REACT_APP_API_URL}/${e}`} alt="" onClick={()=>navigateTo(`/ImagePreview?estate&id=${propertyInfo.id}`)} />
                            </div>
                        ))
                    }
                </div>
            </div>
            {/* <InfiniteScrolling imageList={propertyImageList} setImageList={setPropertyImageList} propertyInfo={propertyInfo} /> */}
            <div className="detail-description">
                <div className="detail-summary">
                    <div className="summary-img">
                        <img src={require('../assets/images/icon/house.png')} alt="" />
                    </div>
                    <div className="summary-content">
                        <p className="summary-title">物件概要</p>
                        <p className={`summary-desc ${descOverflow ? 'desc-overflow' : ""}`}>
                            {propertyInfo.description}
                        </p>
                        {
                            descOverflow ? <img onClick={()=>setDescOverflow(false)} className="summary-icon" src={require("../assets/images/icon/icon-doubleDown.png")} alt="" />
                            :
                            <img className="summary-icon" onClick={()=>setDescOverflow(true)} src={require("../assets/images/icon/icon-doubleUp.png")} />
                        }
                    </div>
                </div>
                <div className="left">
                    <img src={require('../assets/images/icon/icon-mushroom.png')} alt="" />
                </div>
                <div className="right">
                    <img src={require('../assets/images/icon/icon-butterfly.png')} alt="" />
                </div>
            </div>
            <div className="detail-room">
                <div className="room-title">
                    <div className="title">{propertyInfo.name}</div>
                    <div className="subtitle">部屋の予約</div>
                    <div className="line"></div>
                </div>
                <div className="room-content">
                    <div className="room-info">
                        <div className="room-desc">
                            <div className="room-floor">
                                <span>{roomFloor}F</span>
                                <img src={require('../assets/images/icon/title-bg1.png')} alt="" />
                            </div>
                            <div className="room-num">{checkRoom}号室</div>
                            <div className="room-feature">
                                <div className="areaAndSental">
                                    <div className="area">面積：{roomArea}㎡</div>
                                    <div className="rental">家賃：{roomRent}円</div>
                                </div>
                                <div className="desc">{roomDesc}</div>
                            </div>
                        </div>
                        <div className="room-image">
                            {
                                roomLayout ? 
                                <img src={`${process.env.REACT_APP_API_URL}/${roomLayout}`} alt="" />
                                :
                                ""
                            }
                        </div>
                    </div>
                    <div className="room-img-position">
                        <div className="room-img-box">
                            <div className="room-img" 
                                style={{transform: `translateX(-${roomImageMoveLength * 100}%)`}} 
                                onTouchStart={(e)=>touchHandle(e)}
                                onTouchEnd={(e)=>touchEndHandle(e)}
                                onTouchMove={(e)=>handleTouchMove(e)}
                            >
                                {
                                    roomImages?.map((e,i)=>(
                                        <div className="img-content" style={{cursor: 'pointer'}} key={i} onClick={()=>navigateTo(`/ImagePreview?room&id=${roomId}&roomID=${checkRoom}`)}>
                                            <img src={`${process.env.REACT_APP_API_URL}/${e}`} />
                                            {
                                                roomStatus == 1 ? "" : 
                                                <div className='mask'>
                                                    <p>{roomStatus == 2 ? "装修中" : "满室"}</p>
                                                </div>
                                            }
                                        </div>
                                    ))
                                }
                                
                            </div>
                            <div className="room-btn">
                                <div className="img-btn-prev" onClick={()=>roomImagePrev()}><LeftOutlined /></div>
                                <div className="img-btn-next" onClick={()=>roomImageNext()}><RightOutlined /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="room-name">
                    <div className={`room-list ${showAll && 'show-list'}`}>
                        {
                            roomInfo?.map((e,i)=>(
                                <div className={`room-list-item ${checkRoom === e.suiteNO ? "room-list-item-active": ""}`} key={i} onClick={()=>changeRoom(e.suiteNO)}>
                                    {e.suiteNO}
                                </div>
                            ))
                        }
                    </div>
                    {
                        showAll ? 
                        <div className="room-showAll-btn" onClick={()=>setShowAll(false)} >
                            <img src={require("../assets/images/icon/icon-up.png")} alt="" />
                        </div>
                        :
                        <div className="room-showAll-btn" onClick={()=>setShowAll(true)} >
                            <img src={require("../assets/images/icon/icon-down.png")} alt="" />
                        </div>
                    }
                </div>
                <div className="room-bg">

                </div>
            </div>
            
            <div className="detail-intelligence" >
                <div className="intelligence-content">
                    <div className="detail-tabs">
                        <div className="detail-tabs-nav">
                            <ul className="detail-tabs-nav-list">
                                <li className={`nav-list-li ${checkTab === 1 && 'nav-list-li-active'} first-li`} onClick={()=>setCheckTab(1)}>基本情報</li>
                                <li className={`nav-list-li ${checkTab === 2 && 'nav-list-li-active'}`} onClick={()=>setCheckTab(2)}>地図</li>
                                <li className={`nav-list-li ${checkTab === 3 && 'nav-list-li-active'}`} onClick={()=>setCheckTab(3)}>周辺環境</li>
                                <li className={`nav-list-li ${checkTab === 4 && 'nav-list-li-active'} last-li`} onClick={()=>setCheckTab(4)}>設備·その他</li>
                                {/* <li className={`nav-list-li ${checkTab === 5 && 'nav-list-li-active'} last-li`} onClick={()=>setCheckTab(5)}>詳細</li> */}
                            </ul>
                        </div>
                        <div className="detail-tabs-wrapper">
                            {checkTabWrapper()}
                        </div>
                    </div>
                </div>
                <div className="background-left">
                    <img src={require('../assets/images/img/intelligence-background-left.png')} />
                </div>
                <div className="background-right">
                    <img src={require('../assets/images/img/intelligence-background-right.png')} />
                </div>
            </div>
            
            {/* <div className="detail-Panorama">
                <div className="room-title">
                    <div className="title">360°画像</div>
                    <div className="subtitle">シェアプレイス聖蹟桜ヶ丘</div>
                    <div className="line"></div>
                </div>
                <div className="panorama-image">
                </div>
            </div> */}
            <div className="detail-remark">
                <div className="detail-remark-title">
                    <div className="remark-bg">
                        <img src={require('../assets/images/img/bg-wave.png')} alt="" />
                    </div>
                    <div className="remark-title">
                        <p className="title">お問い合わせ</p>
                        <p className="subtitle">なんでも聞いてね</p>
                        <div className="line"></div>
                    </div>
                </div>
                <div className="detail-remark-content">
                    <RemarkComponent type="detail"  />
                </div>
            </div>
            
        </div>
        <div className="detail-footer">
            <IndexFooter />
        </div>
        <div className='detail-beaver' onClick={()=>jumpToBeaver("detail-remark-title")}>
            <img src={require('../assets/images/img/thing-bg2.png')}  alt=''/>
        </div>
    </div>
}



