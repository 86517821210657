

export default function PrivacyPolicy () {


    return <div className="PrivacyPolicy statement-item">
        <div className="statement-title">
            <p className="title-text">プライバシーポリシー</p>
        </div>
        <div className="statement-content">
            <p className="content-text">制定年月日　2023年12月1日</p>
            <p className="content-text">最終改正年月日　2023年12月25日</p>
            <p className="content-text">ハッピーハウジング　株式会社</p>
            <p className="content-text">代表取締役　王　景昌</p>
            <p className="content-text">当社は、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守します。また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言します。</p>
            <p className="content-text">個人情報は、Webコンサルティング事業部、インターネットメディア事業部における当社の正当な事業遂行上並びに従業員の雇用、人事管理上必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱い（目的外利用）を行いません。また、目的外利用を行わないための措置を講じます。</p>
            <p className="content-text">個人情報保護に関する法令、国が定める指針及びその他の規範を遵守致します。</p>
            <p className="content-text">個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。また、個人情報保護上、問題があると判断された場合には速やかに是正措置を講じます。</p>
            <p className="content-text">個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に、適切な対応をさせていただきます。</p>
            <p className="content-text paragraph">個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直してその改善を継続的に推進します。</p>
            <p className="content-text strong">お問合せ窓口</p>
            <p className="content-text">個人情報保護方針に関するお問合せにつきましては、下記窓口で受付けております。</p>
            <p className="content-text">ハッピーハウジング　株式会社 　個人情報問合せ窓口</p>
            <p className="content-text">〒101-0051 東京都千代田区神田神保町3-2 アオキビスポークビル7F</p>
            <p className="content-text">メールアドレス：info@hhpm.co　TEL：03-6261-6656</p>
            <p className="content-text paragraph">（受付時間　10:00～18:00　※土日、祝日、年末年始、ゴールデンウィークを除く）</p>
            <p className="content-text strong">個人情報の取扱いについて</p>
            <p className="content-text strong">１．当社が取り扱う個人情報の利用目的</p>
            <p className="content-text">ご本人から直接書面によって取得する個人情報（ホームページや電子メール等によるものを含む）の利用目的</p>
            <p className="content-text paragraph">取得に先立ち、ご本人に対し書面により明示します。</p>
            <p className="content-text">前項以外の方法によって取得する個人情報の利用目的</p>
            <div className="content-table">
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">分類</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">利用目的</p>
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">個人のお客様情報</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">ご利用履歴管理のため</p>
                        <p className="item-text">お問合せ対応のため</p>
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">お取引先担当者様情報</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">発注内容確認のため（通信記録等）</p>
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">業務の受託に伴い、お客様からお預かりする個人情報</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">委託された当該業務を適切に遂行するため</p>
                    </div>
                </div>
            </div>
            <p className="content-text strong">■ 保有個人データに関する事項の周知</p>
            <p className="content-text">当社で保有している保有個人データ又は第三者提供記録に関して、ご本人様又はその代理人様からの利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止の請求（以下、「開示等の請求」といいます）につきましては、以下の要領にて対応させていただきます。</p>
            <p className="content-text">事業者の名称</p>
            <p className="content-text">ハッピーハウジング　株式会社 </p>
            <p className="content-text">〒101-0051 東京都千代田区神田神保町3-2 アオキビスポークビル7F</p>
            <p className="content-text">個人情報の保護管理者</p>
            <p className="content-text">職　名：代表取締役 </p>
            <p className="content-text">所　属：管理本部</p>
            <p className="content-text">連絡先：info@hhpm.co / 03-6261-6656</p>
            <p className="content-text paragraph">全ての保有個人データの利用目的</p>
            <div className="content-table">
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">分類</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">利用目的</p>
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">個人のお客様情報</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">ユーザー様サポートのため</p>
                        <p className="item-text">ご利用履歴管理のため</p>
                        <p className="item-text">当社サービスのご案内のため</p>
                        <p className="item-text">お問合せ対応のため</p>
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">当社従業者情報</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">従業者の人事労務管理、業務管理、健康管理、セキュリティ管理のため</p>
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">当社への採用応募者情報</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">採用応募者への連絡と当社の採用業務管理のため</p>
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">特定個人情報</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">番号利用法に定められた利用目的のため</p>
                    </div>
                </div>
            </div>
            <p className="content-text">保有個人データの取扱いに関する苦情の申し出先</p>
            <p className="content-text">ハッピーハウジング　株式会社 　個人情報問合せ窓口</p>
            <p className="content-text">〒101-0051 東京都千代田区神田神保町3-2 アオキビスポークビル7F</p>
            <p className="content-text paragraph">メールアドレス：info@hhpm.co　TEL：03-6261-6656</p>
            <p className="content-text">認定個人情報保護団体の名称及び苦情の解決の申出先</p>
            <p className="content-text">【個人情報の取り扱いに関する苦情のみを受付けています】</p>
            <p className="content-text">一般財団法人日本情報経済社会推進協会</p>
            <p className="content-text">認定個人情報保護団体事務局</p>
            <p className="content-text">〒106-0032 東京都港区六本木一丁目９番９号 六本木ファーストビル内</p>
            <p className="content-text">03-5860-7565　／　0120-700-779</p>
            <p className="content-text">保有個人データ又は第三者提供記録の開示等の求めに応じる手続き</p>
            <p className="content-text">開示等の求めの申し出先</p>
            <p className="content-text">開示等のお求めは、上記個人情報問合せ窓口にお申し出ください。</p>
            <p className="content-text paragraph">※電磁的手続きによる開示等をご希望の方は、その旨お申し出ください。原則としてご希望に沿って処理させていただきます。</p>
            <p className="content-text">開示等の求めに関するお手続き</p>
            <p className="content-text">①お申し出受付け後、当社からご利用いただく所定の請求書様式「保有個人データ開示等請求書」を郵送いたします。</p>
            <p className="content-text">②ご記入いただいた請求書、代理人によるお求めの場合は代理人であることを確認する書類、手数料分の郵便為替（利用目的の通知並びに開示の請求の場合のみ）を上記個人情報問合せ窓口までご郵送ください。</p>
            <p className="content-text">③上記請求書を受領後、ご本人確認のため、当社に登録していただいている個人情報のうちご本人確認可能な2項目程度（例：電話番号と生年月日等）の情報をお問合せさせていただきます。</p>
            <p className="content-text paragraph">④回答は原則としてご本人に対して書面（封書郵送）にておこないます。</p>
            <p className="content-text">代理人によるお求めの場合、代理人であることを確認する資料</p>
            <p className="content-text">開示等をお求めになる方が代理人様である場合は、代理人である事を証明する資料及び代理人様ご自身を証明する資料を同封してください。各資料に含まれる本籍地情報は都道府県までとし、それ以降の情報は黒塗り等の処理をしてください。また各資料は個人番号を含まないものをお送りいただくか、全桁を黒塗り等の処理をしてください。</p>
            <p className="content-text">①代理人である事を証明する資料</p>
            <p className="content-text">＜開示等の求めをすることにつき本人が委任した代理人様の場合＞</p>
            <p className="content-text">本人の委任状（原本）</p>
            <p className="content-text">＜代理人様が未成年者の法定代理人の場合＞いずれかの写し</p>
            <p className="content-text">戸籍謄本</p>
            <p className="content-text">住民票（続柄の記載されたもの）</p>
            <p className="content-text">その他法定代理権の確認ができる公的書類</p>
            <p className="content-text">＜代理人様が成年被後見人の法定代理人の場合＞いずれかの写し</p>
            <p className="content-text">後見登記等に関する登記事項証明書</p>
            <p className="content-text">その他法定代理権の確認ができる公的書類</p>
            <p className="content-text">②代理人様ご自身を証明する資料</p>
            <p className="content-text">運転免許証</p>
            <p className="content-text">パスポート</p>
            <p className="content-text paragraph">健康保険の被保険者証（被保険者等記号・番号等は全桁を黒塗りしてご提出ください）</p>
            <p className="content-text">住民票</p>
            <p className="content-text">利用目的の通知または開示のお求めについての手数料</p>
            <p className="content-text">1回のお求めにつき1,000円</p>
            <p className="content-text paragraph">（書面でのご請求の場合は、お送りいただく請求書等に郵便為替を同封していただきます。その他の方法でご請求いただく場合は、ご請求時にご相談させていただきます。）</p>
            <p className="content-text">個人情報の取扱体制や講じている措置の内容</p>
            <p className="content-text">基本方針の策定</p>
            <p className="content-text">保有個人データの適正な取扱いの確保のため、「関係法令・ガイドライン等の遵守」、「質問及び苦情処理の窓口」等について「個人情報保護方針」を策定しています。</p>
            <p className="content-text">保有個人データの取扱いに係る規律の整備</p>
            <p className="content-text paragraph">保有個人データは取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法、責任者・担当者及びその任務等について個人情報保護規程を策定しています。</p>
            <p className="content-text">組織的安全管理措置</p>
            <p className="content-text">①保有個人データの取扱いに関する責任者を設置するとともに、保有個人データを取り扱う従業者及び当該従業者が取り扱う保有個人データの範囲を明確化し、法や取扱規程に違反している事実又は兆候を把握した場合の責任者への報告連絡体制を整備しています。</p>
            <p className="content-text paragraph">②保有個人データの取扱状況について、定期的に自己点検を実施するとともに、他部署や外部の者による監査を実施しています。</p>
            <p className="content-text">人的安全管理措置</p>
            <p className="content-text">①保有個人データの取扱いに関する留意事項について、従業者に定期的な研修を実施しています。</p>
            <p className="content-text paragraph">②保有個人データを含む秘密保持に関する誓約書の提出を全従業者から受けています。</p>
            <p className="content-text">物理的安全管理措置</p>
            <p className="content-text">①保有個人データを取り扱う区域において、従業者の入退室管理及び持ち込む機器等の制限を行うとともに、権限を有しない者による保有個人データの閲覧を防止する措置を講じています。</p>
            <p className="content-text paragraph">②保有個人データを取り扱う機器、電子媒体及び書類等の盗難又は紛失等を防止するための措置を講じるとともに、事業所内の移動を含め、当該機器、電子媒体等を持ち運ぶ場合、容易に保有個人データが判明しないよう措置を講じています。</p>
            <p className="content-text">技術的安全管理措置</p>
            <p className="content-text">①アクセス制御を実施して、担当者及び取り扱う個人情報データベース等の範囲を限定しています。</p>
            <p className="content-text">②保有個人データを取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入しています。</p>
            <p className="content-text">以上</p>
        </div>
    </div>
}

