


export default function PersonalInformationProtection () {


    return <div className="PersonalInformationProtection statement-item">
        <div className="statement-title">
            <p className="title-text">個人情報保護方針</p>
        </div>
        <div className="statement-content">
            <p className="content-text">個人情報保護方針</p>
            <p className="content-text">制定年月日　2023年12月1日</p>
            <p className="content-text">最終改正年月日　2023年12月25日</p>
            <p className="content-text">ハッピーハウジング 株式会社</p>
            <p className="content-text paragraph">代表取締役 王　景昌</p>
            <p className="content-text paragraph">当社は、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守します。また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言します。</p>
            <p className="content-text paragraph">個人情報は、不動産賃貸借、売買事業部、における当社の正当な事業遂行上並びに従業員の管理上必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱い（目的外利用）を行いません。また、目的外利用を行わないための措置を講じます。</p>
            <p className="content-text paragraph">個人情報保護に関する法令、国が定める指針及びその他の規範を遵守致します。</p>
            <p className="content-text paragraph">個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。また、個人情報保護上、問題があると判断された場合には速やかに是正措置を講じます。</p>
            <p className="content-text paragraph">個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に、適切な対応をさせていただきます。</p>
            <p className="content-text ">個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直してその改善を継続的に推進します。</p>
            <p className="content-text paragraph">お問合せ窓口</p>
            <p className="content-text">個人情報保護方針に関するお問合せにつきましては、下記窓口で受付けております。</p>
            <p className="content-text">ハッピーハウジング　株式会社 　個人情報問合せ窓口</p>
            <p className="content-text">〒101-0051 東京都千代田区神田神保町3-2 アオキビスポークビル7F</p>
            <p className="content-text">メールアドレス：info@hhpm.co　TEL：03-6261-6656</p>
            <p className="content-text paragraph">（受付時間　10:00～18:00　※土日、祝日、年末年始、ゴールデンウィークを除く）</p>
            <p className="content-text strong ">個人情報の取扱いについて</p>
            <p className="content-text strong ">１．当社が取り扱う個人情報の利用目的</p>
            <p className="content-text">ご本人から直接書面によって取得する個人情報（ホームページや電子メール等によるものを含む）の利用目的取得に先立ち、ご本人に対し書面により明示します。</p>
            <p className="content-text paragraph">前項以外の方法によって取得する個人情報の利用目的</p>
            <div className="content-table">
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">分類</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">利用目的</p>
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">個人のお客様情報</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">ご利用履歴管理のため</p>
                        <p className="item-text">お問合せ対応のため</p>
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">お取引先担当者様情報</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">発注内容確認のため（通信記録等）</p>
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">業務の受託に伴い、お客様からお預かりする個人情報</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">委託された当該業務を適切に遂行するため</p>
                    </div>
                </div>
            </div>
            <p className="content-text strong ">■ 保有個人データに関する事項の周知</p>
            <p className="content-text">当社で保有している保有個人データに関して、ご本人様又はその代理人様からの利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止の請求（以下、「開示等の請求」といいます）につきましては、以下の要領にて対応させていただきます。</p>
            <p className="content-text">事業者の名称</p>
            <p className="content-text">ハッピーハウジング　株式会社</p>
            <p className="content-text">個人情報の保護管理者</p>
            <p className="content-text">管理者名：王　景昌</p>
            <p className="content-text">所属部署：管理本部</p>
            <p className="content-text">連絡先：メールアドレス：info@hhpm.co　TEL：03-6261-6656</p>
            <p className="content-text">全ての保有個人データの利用目的</p>
            <div className="content-table">
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">分類</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">利用目的</p>
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">個人のお客様情報</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">ユーザー様サポートのため</p>
                        <p className="item-text">ご利用履歴管理のため</p>
                        <p className="item-text">当社サービスのご案内のため</p>
                        <p className="item-text">お問合せ対応のため</p>
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">当社従業員情報</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">社員の人事労務管理、業務管理、健康管理、セキュリティ管理のため</p>
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">当社への採用応募者情報</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">採用応募者への連絡と当社の採用業務管理のため</p>
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-item">
                        <p className="item-text">特定個人情報</p>
                    </div>
                    <div className="row-item">
                        <p className="item-text">番号法に定められた利用目的のため</p>
                    </div>
                </div>
            </div>
            <p className="content-text">保有個人データの取扱いに関する苦情の申し出先</p>
            <p className="content-text">ハッピーハウジング　株式会社 　個人情報問合せ窓口</p>
            <p className="content-text">連絡先：メールアドレス：info@hhpm.co　TEL：03-6261-6656</p>
            <p className="content-text paragraph">（受付時間　10:00～18:00　※土日、祝日、年末年始、ゴールデンウィークを除く）</p>
            <p className="content-text">認定個人情報保護団体</p>
            <p className="content-text">当社は、下記の認定個人情報保護団体の対象事業者です。</p>
            <p className="content-text">当社の個人情報の取り扱いに関する苦情については、下記へお申し出いただくこともできます。</p>
            <p className="content-text">一般財団法人日本情報経済社会推進協会 　個人情報保護苦情相談室</p>
            <p className="content-text">＜住　　所＞　 〒106-0032　東京都港区六本木1-9-9　六本木ファーストビル12F</p>
            <p className="content-text">＜電話番号＞　03-5860-7565　0120-700-779</p>
            <p className="content-text ">【当社の商品・サービスに関する問合せ先ではございません】</p>
            <p className="content-text strong">■お客様が使用する端末に関する情報およびcookie等を利用して取得する情報の利用目的</p>
            <p className="content-text paragraph">お客様が使用する端末に関する情報およびcookie等を利用して取得する情報について</p>
            <p className="content-text aggravate">・cookie</p>
            <p className="content-text">ユーザーエクスペリエンスの向上を図るためWebサイトがどのように利用さているかなどを把握する目的でcookieを利用します。cookieはブラウザに利用者の利用履歴や入力内容などを一時的に利用者のコンピュータへデータとして記録する仕組みです</p>
            <p className="content-text">cookie無効化について</p>
            <p className="content-text">本サービスでは提携先の第三者による広告を配信し、または、そのような提携先を介して本サービスの広告を配信することを目的として、提携先の第三者の発行するcookieによる行動情報の取得・利用が行われる場合があります。</p>
            <p className="content-text">当該第三者のcookieの詳細および取得される情報は、当該第三者のプライバシーポリシー（個人情報保護方針）等の取り決めに従って取り扱われます。</p>
            <p className="content-text">cookieの受け取りはインターネット閲覧ソフト（ブラウザ）の設定により、拒否することが可能です。</p>
            <p className="content-text">しかし、拒否することで一部のサービスをお客様が利用できなることがありますので、当社のサービスをご利用の場合は、ブラウザ設定はクッキーを有効にした状態でご利用ください。</p>
            <p className="content-text aggravate">・端末の個体識別番号</p>
            <p className="content-text">アクセス管理などに利用し、携帯端末で本サービスを訪れた際に携帯端末を区別するために利用者の携帯端末が送信する番号を取得することがあります。</p>
            <p className="content-text aggravate">・端末識別子</p>
            <p className="content-text">アクセス管理などに利用し、iOS版・Android版キレカワアプリを利用するスマートデバイス端末から端末識別子（Apple社が発行するIDFAやGoogle社が発行するAAIDなどの広告識別子を含みます）を取得することがあります。なお、IDFAやAAIDなどの広告識別子については、広告配信の最適化目的のためにのみ利用いたします。</p>
            <p className="content-text aggravate">・位置情報</p>
            <p className="content-text">利用者の位置情報（経度緯度情報）を利用した本サービスを提供するため、利用者の同意がある場合に取得します。</p>
            <p className="content-text aggravate">・Webビーコン</p>
            <p className="content-text">利用者の識別および利用状況などの情報を取得するため、Webビーコンと呼ばれる、透明な画像ファイルやスクリプトなどを使用しているページがあります。</p>
            <p className="content-text paragraph">利用目的について</p>
            <p className="content-text">当社は、以下の目的で個人情報を利用します。</p>
            <p className="content-text">１）　不動産の賃貸借・売買・交換等の媒介・仲介等に必要な情報の提供</p>
            <p className="content-text">２）　不動産商品、情報、サービス提供のための郵便物、電話、電子メール等による営業活動及びアンケートのお願い等のマーケティング活動、顧客動向分析または商品開発等の調査分析</p>
            <p className="content-text">３）　上記１ないし３の利用目的達成に必要な範囲での、個人情報の第三者への提供</p>
            <p className="content-text">４）　お問合せ・ご相談への対応</p>
        </div>
    </div>
}


