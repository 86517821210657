import {LeftOutlined, MenuOutlined, AppstoreOutlined, FullscreenOutlined} from "@ant-design/icons"
import "../assets/style/ImagePreview.scss"
import { useEffect, useState } from "react"
import { Button, Dropdown, Modal } from "antd"
import CloseIcon from "../component/CloseIcon"
import { useNavigate, useLocation } from "react-router-dom"
import { getPropertyInfo, getRoomInfo } from "../request/api"
import { useViewport } from "../hooks/useViewport"


export default function ImagePreview () {
    const navigateTo = useNavigate()
    const location = useLocation()
    const { width } = useViewport()

    // 选择显示模式
    let [switchModal,setSwitchModal] = useState(1)
    // 全屏查看大图Modal
    let [showLargeModal,setShowLargeModal] = useState(false)
    // 图片列表
    let [imgList,setImgList] = useState([])
    let [type,setType] = useState()
    // 选择的图片
    let [checkImage,setCheckImage] = useState()
    // 选择的物业id
    let [estateId,setEstateId] = useState()
    let [headerClass,setHeaderClass] = useState()
    // 选择的房间id
    let [roomID,setRoomID] = useState()


    const items = [
        {
            key: "1",
            label: (
                <div className="tip">
                    <span className="tip-title">東京</span>
                    <span className="tip-content">03-6261-6621</span>
                </div>
            )
        },
    ]

    const modalChange = (val) => {
        if (type==="environment"){
            checkImage = val.image;
            setCheckImage(checkImage)
        }else{
            checkImage = val;
            setCheckImage(val)
        }
        setShowLargeModal(true)
    }

    const getImages = async (type,checkId) => {
        if (type==="estate") {
            await getPropertyInfo({
                id: checkId
            })
            .then((res) => {
                console.log("estate====>",res);
                if (res.code === 0 && res.data) {
                    let list = res.data.images.split(",")
                    console.log("list====>",list);
                    imgList = list;
                    setImgList([...imgList])
                }
            }).catch((err) => {
                
            });
        }else if (type==="room") {
            await getRoomInfo({
                id: checkId
            })
            .then((res) => {
                if (res.code === 0) {
                    let list = res.data.list;
                    console.log(list);
                    list.map((e)=>{
                        if (e.suiteNO == roomID) {
                            let arr = e.images.split(",")
                            imgList = arr;
                            setImgList([...imgList])
                        }
                    })
                    // setImgList([...list])
                }
            }).catch((err) => {
                
            });
        }else if (type==="environment") {
            await getPropertyInfo({
                id: checkId
            })
            .then((res) => {
                console.log("estate====>",res);
                if (res.code === 0 && res.data) {
                    let list = JSON.parse(res.data.info.surroundings)
                    console.log("list=====>",list);
                    imgList = list;
                    setImgList([...imgList])
                }
            }).catch((err) => {
                
            });
        }
    }

    const handleScroll = () => {
        let header = document.getElementsByClassName('preview-footer');
        if ( window.pageYOffset >= 40 ) {
            if (header[0]) {
                header[0].classList.add('preview-footer-fix');
            }
            // headerClass = 'preview-footer';
            // setHeaderClass(headerClass)
        }
        if ( window.pageYOffset === 0 ) {
            if (header[0]) {
                header[0].classList.remove('preview-footer-fix');
            }
            // headerClass = '';
            // setHeaderClass(headerClass)
        }
      }

    const toRemark = () => {
        sessionStorage.setItem("toRemark",estateId)
        navigateTo(`/Detail?${estateId}`)
    }


      useEffect(()=>{
        window.addEventListener('scroll',handleScroll,true)
      },[])

    useEffect(()=>{
        if (location) {
            let str = location.search.split('?')[1]
            let checkType = str.split("&")[0]
            type = checkType;
            setType(checkType)
            let checkId = str.split("=")[1]
            let id = str.split("=")[2];
            roomID = id
            setRoomID(roomID)
            if (checkId.indexOf('&') > -1) {
                checkId = checkId.split('&')[0]
            }
            estateId = checkId;
            setEstateId(estateId)
            getImages(checkType,checkId)
        }
    },[location])


    return <div className={`ImagePreview ${width < 481 && 'ImagePreviewMobile'}`}>
        <div className="preview-header">
            <div className="header-exit" onClick={()=>navigateTo(-1)}>
                <div className="exit-icon"><LeftOutlined /></div>
                <p className="exit-text">戻る</p>
            </div>
            <div className="header-title">
                <p className="title-text">画像一览</p>
            </div>
            <div className="header-switch">
                <div className={`switch-item ${switchModal === 1 ? 'switch-item-active' : ""}`} onClick={()=>setSwitchModal(1)}><MenuOutlined /></div>
                <div className={`switch-item ${switchModal === 2 ? 'switch-item-active' : ""}`} onClick={()=>setSwitchModal(2)}><AppstoreOutlined /></div>
            </div>
        </div>
        <div className="preview-content">
            <p className="content-title">{`全${imgList?.length}枚`}</p>
            {
                switchModal === 1 ? <div className="content-image-list-large">
                    {
                        imgList.map((e,i)=>(
                            <div className="image-list-item" key={i}>
                                <div className="item-image">
                                    {
                                        type === "environment" ? <img src={`${process.env.REACT_APP_API_URL}/${e.image}`} alt="" />
                                        :
                                        <img src={`${process.env.REACT_APP_API_URL}/${e}`} alt="" />
                                    }
                                    <div className="mask">
                                        <div className="sequence">{i+1}/{imgList?.length}</div>
                                        <img src={require('../assets/images/icon/icon-large.png')} className="enlarge" onClick={()=>modalChange(e)} alt="" />
                                    </div>
                                </div>
                                {
                                    type === "environment" && <div className="item-title">{e.description}</div>
                                }
                            </div>
                        ))
                    }
                    {/* <div className="image-list-item">
                        <div className="item-image">
                            <img src={`${process.env.REACT_APP_API_URL}/uploads/file/3cd339f1d243b3fb5c49004b54145908_20230706154508.png`} alt="" />
                            <div className="mask">
                                <div className="sequence">1/20</div>
                                <div className="enlarge"><FullscreenOutlined /></div>
                            </div>
                        </div>
                        <div className="item-title">聖蹟桜ヶ丘駅徒歩8分</div>
                        <div className="item-subtitle">聖蹟桜ヶ丘駅徒歩8分</div>
                    </div>
                    <div className="image-list-item">
                        <div className="item-image">
                            <img src={`${process.env.REACT_APP_API_URL}/uploads/file/3cd339f1d243b3fb5c49004b54145908_20230706154508.png`} alt="" />
                            <div className="mask">
                                <div className="sequence">1/20</div>
                                <div className="enlarge"><FullscreenOutlined /></div>
                            </div>
                        </div>
                        <div className="item-title">聖蹟桜ヶ丘駅徒歩8分</div>
                        <div className="item-subtitle">聖蹟桜ヶ丘駅徒歩8分</div>
                    </div>
                    <div className="image-list-item">
                        <div className="item-image">
                            <img src={`${process.env.REACT_APP_API_URL}/uploads/file/3cd339f1d243b3fb5c49004b54145908_20230706154508.png`} alt="" />
                            <div className="mask">
                                <div className="sequence">1/20</div>
                                <div className="enlarge"><FullscreenOutlined /></div>
                            </div>
                        </div>
                        <div className="item-title">聖蹟桜ヶ丘駅徒歩8分</div>
                        <div className="item-subtitle">聖蹟桜ヶ丘駅徒歩8分</div>
                    </div> */}
                </div>
                :
                <div className="content-image-list-small">
                    {
                        imgList.map((e,i)=>(
                            <div className="image-list-item" key={i}>
                                <div className="item-image">
                                    {
                                        type === "environment" ? <img src={`${process.env.REACT_APP_API_URL}/${e.image}`} alt="" />
                                        :
                                        <img src={`${process.env.REACT_APP_API_URL}/${e}`} alt="" />
                                    }
                                    <div className="mask">
                                        <div className="sequence">{i+1}/{imgList?.length}</div>
                                        {/* <div className="enlarge" onClick={()=>modalChange(e)}><FullscreenOutlined /></div> */}
                                        <img src={require('../assets/images/icon/icon-large.png')} className="enlarge" onClick={()=>modalChange(e)} alt="" />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
        <div className="preview-footer">
            <div className="info-request">
                <Button type="primary" onClick={()=>toRemark()}>内覧予約</Button>
            </div>
            <div className="phone-contact">
                <Dropdown
                    menu={{
                        items
                    }}
                    arrow
                    placement="top"
                    overlayClassName={`contact-list ${width < 481 && 'contact-list-mobile'}`}
                >
                    <Button>電話番号</Button>
                </Dropdown>
            </div>
        </div>
        <Modal
            open={showLargeModal}
            className={`large-image-modal ${width < 481 && 'large-image-modal-mobile'}`}
            onCancel={()=>setShowLargeModal(false)}
            footer={false}
            closeIcon={<CloseIcon />}
        >
            <div className="large-image">
                <img src={`${process.env.REACT_APP_API_URL}/${checkImage}`} alt="" />
            </div>
        </Modal>
    </div>
}


