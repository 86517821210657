import { Button, Input, Select, message } from 'antd'
import { useEffect, useState } from 'react'
import { getPropertyList, getRoomInfo, postRemark } from '../request/api';
import { useLocation, useNavigate } from 'react-router-dom';
import validator from 'validator';


export default function RemarkComponent (params) {

    const { propertyList, type, setAdvisoryStatus } = params;
    const location = useLocation()
    const navigateTo = useNavigate()

    // 预约内容
    let [remarkContent,setRemarkContent] = useState({
        name: "",
        tel: "",
        email: "",
        property: "",
        room: "",
        desc: ""
    })
    // 物业列表
    let [propertyCol,setPropertyCol] = useState([])
    // 选择的物业
    let [checkProperty,setCheckProperty] = useState()
    // 房间列表
    let [roomCol,setRoomCol] = useState([])


    const valueChange = (type,val) => {
        remarkContent[type] = val;
        setRemarkContent({...remarkContent})
    }

    const isValidEmail = (email) => {
        return validator.isEmail(email)
    }

    // const propertyCol = [
    //     {
    //         value: "大阪  シェアプレイス駒沢 ",
    //         label: "大阪  シェアプレイス駒沢 "
    //     }
    // ]

    const getProperty = async (id) => {
        await getPropertyList()
        .then((res) => {
            if (res.code === 0) {
                if (res.data.list) {
                    let arr = []
                    res.data.list.map((e)=>{
                        arr.push({
                            value: e.id,
                            label: e.name
                        })
                    })
                    setPropertyCol([...arr])
                    let newId = Number(id)
                    setCheckProperty(newId)
                }
            }
        }).catch((err) => {
            
        });
    }    

    const getRoom = async () => {
        await getRoomInfo({
            id: checkProperty
        })
        .then((res) => {
            if (res.code === 0) {
                let arr = []
                if (res.data.list) {
                    res.data.list.map((e)=>{
                        arr.push({
                            value: e.id,
                            label: e.suiteNO
                        })
                    })
                }
                setRoomCol([...arr])
            }
        }).catch((err) => {
            
        });
    }

    const send = async () => {
        let checkRoom = ""
        if (remarkContent.room) {
            roomCol.map((e)=>{
                if (e.value === remarkContent.room) {
                    checkRoom = e.label
                }
            })
        }
        let obj = {
            name: remarkContent.name,
            telephone: remarkContent.tel,
            email: remarkContent.email,
            estateID: checkProperty,
            suite: checkRoom,
            description: remarkContent.desc,
            time: Math.floor(Date.now() / 1000)
        }
        let isemail = isValidEmail(obj.email)
        if (!obj.name) {
            message.error("お名前（漢字+フリガナ）を記入してください")
            return false
        }else if (!obj.telephone) {
            message.error("電話番号を記入してください")
            return false
        }else if (!obj.email) {
            message.error("Email アドレスを記入してください")
            return false
        }else if (!isemail) {
            message.error("Email アドレスを記入してください")
            return false
        }else if (!obj.description) {
            message.error("お問い合わせ内容を記入してください")
            return false
        }
        await postRemark(obj)
        .then((res) => {
            console.log("res====>",res);
            if (res.code===0) {
                message.success(res.msg)
                if (type !== 'advisory') {
                    setTimeout(()=>{
                        navigateTo("/Advisory?success")
                    },1000)
                }else{
                    setAdvisoryStatus(true)
                }
            }else{
                message.error(res.msg)
            }
        }).catch((err) => {
            
        });
    }


    useEffect(()=>{
        if (propertyList) {
            let arr = []
            propertyList.map((e)=>{
                arr.push({
                    value: e.id,
                    label: e.name
                })
            })
            propertyCol = arr;
            setPropertyCol([...propertyCol])
        }
    },[propertyList])

    useEffect(()=>{
        if (type === "detail" && location) {
            let id = location.search.split("?")[1]
            getProperty(id)
        }
    },[location])

    useEffect(()=>{
        if (checkProperty) {
            getRoom()
        }
    },[checkProperty])

    return <div className="RemarkComponent">
        <div className='content'>
            <p className='title item'>予約なしでも内覧できますが、まず問い合わせしましょう</p>
            <div className='name item'>
                <p>お名前（漢字+フリガナ）*</p>
                <Input value={remarkContent.name} onChange={(e)=>valueChange("name",e.target.value)} />
            </div>
            <div className='connect item'>
                <div className='tel'>
                    <p>電話番号*</p>
                    <Input value={remarkContent.tel} onChange={(e)=>valueChange("tel",e.target.value)} />
                </div>
                <div className='email'>
                    <p>Email アドレス*</p>
                    <Input value={remarkContent.email} onChange={(e)=>valueChange("email",e.target.value)} />
                </div>
            </div>
            <div className='room item'>
                <p>物件名</p>
                <div className="select">
                    <Select 
                        options={propertyCol} 
                        value={checkProperty} 
                        onChange={(e)=>setCheckProperty(e)} 
                        suffixIcon={<img src={require("../assets/images/icon/icon-downOutline.png")} alt='' />}
                    />
                    <Select 
                        options={roomCol} 
                        value={remarkContent.room} 
                        onChange={(e)=>valueChange("room",e)} 
                        suffixIcon={<img src={require("../assets/images/icon/icon-downOutline.png")} alt='' />}
                    />
                </div>
            </div>
            <div className='desc item'>
                <p>お問い合わせ内容*</p>
                <Input.TextArea autoSize className='textArea' value={remarkContent.desc} onChange={(e)=>valueChange("desc",e.target.value)} />
            </div>
            <div className='btn'>
                <Button onClick={()=>send()}>送信</Button>
            </div>
        </div>
    </div>
}


