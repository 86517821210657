import "../assets/style/Advisory.scss"
import { Button } from "antd"
import { useNavigate, useLocation } from "react-router-dom"
import { useViewport } from "../hooks/useViewport"
import IndexFooter from "../component/IndexFooter"
import { useEffect, useState } from "react"
import RemarkComponent from "../component/RemarkComponent"
import { getPropertyList } from "../request/api"
import ListDataDity from "../utils/ListDataDity"
// import IndexFooter from "../component/IndexFooter"


export default function Advisory () {
    const navigateTo = useNavigate()
    const {width} = useViewport()
    const location = useLocation()

    let [propertyList,setPropertyList] = useState([]);
    let [advisoryStatus,setAdvisoryStatus] = useState(false)

    const getData = async () => {
        await getPropertyList()
        .then((res) => {
            let arr = ListDataDity({
                data: res.data.list,
                type: "index"
            })
            setPropertyList([...arr])
        }).catch((err) => {
            
        });
    }
 
    useEffect(() => {
        // 在组件加载完成后，将页面滚动至顶部
        // window.scrollTo(0, 0);
        setTimeout(() => window.scrollTo(0,0), 50)
        // window.onbeforeunload = function(){
        //     //刷新后页面自动回到顶部
        //     document.documentElement.scrollTop = 0;  //ie下
        //     document.body.scrollTop = 0;
        // }
      }, []);

    useEffect(()=>{
        getData()
    },[])

    useEffect(()=>{
        let status = location.search?.split('?')[1];
        if (status === 'success') {
            setAdvisoryStatus(true)
        }
    },[location])

    return <div className={`Advisory ${width < 481 && 'AdvisoryMobile'}`}>
        {
            advisoryStatus ? <div className="advisory-complete">
                <div className="advisory-content">
                    <div className="advisory-success">
                        <div className="success-content">
                            <h3>※このメールは自動返信メールです。</h3>
                            <div className="text">
                                <p>お問い合わせを頂戴し、誠にありがとうございます。</p>
                                <p>お問い合わせについては、二営業日以内担当者より連絡申し上げますので、今しばらくお待ちくださいませ。</p>
                            </div>
                            <Button type="primary" onClick={()=>navigateTo(-1)}>もどる</Button>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className="advisory-write">
                <div className='remark-title'>
                    <p className='title'>お問い合わせ</p>
                    <p className='subtitle'> なんでも聞いてね</p>
                    <div className='line'></div>
                </div>
                <div className="remark-content">
                    <RemarkComponent propertyList={propertyList} type="advisory" setAdvisoryStatus={setAdvisoryStatus} />
                </div>
            </div>
        }
        <div className="detail-footer">
            <IndexFooter />
        </div>
    </div>
} 


