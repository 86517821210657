
import { Layout } from 'antd';
import routes from './router';
import { useRoutes } from 'react-router-dom'
import AppHeader from './containers/AppHeader';
import AppFooter from './containers/AppFooter';
import { detectZoom } from './utils/detectZoom';
import {useEffect,useState} from 'react'
import './assets/style/container.scss'
import { useNavigate, useLocation } from 'react-router-dom'
import { useViewport } from './hooks/useViewport';
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';

const { Header, Footer, Content } = Layout;
const m = detectZoom();


function App() {
  const outlet = useRoutes(routes);
  const baseSize = 16;
  const { width } = useViewport()

  const location = useLocation()

  let [netPath,setNetPath] = useState();
  let [isLoading,setIsLoading] = useState(true)

  if (window.screen.width * window.devicePixelRatio >=(1920 * 2)) {
    switch (m) {
      // 4k ==> 100%
      case 100:
        document.body.style.zoom = 100 / (0.625 * 2560);
        break;
        // 4k ==> 125%
      case 125:
        document.body.style.zoom = 100 / (0.625 * 2560);
        break;
        // 4k ==> 150%
      case 150:
        document.body.style.zoom = 100 / (0.75 * 2560);
        break;
        // 4k ==> 175%
      case 175:
        document.body.style.zoom = 100 / (0.874715 * 2560);
        break;
        // 4k ==> 200%
      case 200:
        document.body.style.zoom = 1920 / 2560;
        break;
        // 4k ==> 225%
      case 225:
        document.body.style.zoom = 100 / (1.12485 * 2560);
        break;
    
      default:
        break;
    }
  }
  else if(m === 100 && window.screen.width === 1920 && window.devicePixelRatio === 1){
      
    document.body.style.zoom = 1920 / 2560;
  }
  else if (window.screen.width <= 1921 && window.screen.width >= 1440) {
    document.body.style.zoom = 1440 / 1920;
  }else{
    console.log("m===>",m);

    // document.body.style.zoom = 1920 / (window.screen.width * window.devicePixelRatio)
      // document.body.style.zoom = 2560 / 1920;
      // console.log();
  }

  const setRemUnit = () => {
    const clientWidth = document.documentElement.clientWidth;
    if(clientWidth < 481) {
      const scale = clientWidth / 375;
      console.log("scale====>",scale);
      document.documentElement.style.fontSize = baseSize * Math.min(scale,4)+'px'
    }else if (clientWidth < 764 && clientWidth > 480) {
      const scale = clientWidth / 375;
      console.log("scale====>",scale);
      document.documentElement.style.fontSize = baseSize * Math.min(scale,4)+'px'
      getRem(2560, 100)

    }else if (clientWidth > 765 && clientWidth < 1367) {
      const scale = clientWidth / 2560;
      document.documentElement.style.fontSize = 100 * scale + 'px'
      getRem(2560,100)
      console.log(2);
    }else{
      getRem(2560, 100)
    }
}

// window.onload = function () {
//   /*720代表设计师给的设计稿的宽度，你的设计稿是多少，就写多少;100代表换算比例，这里写100是
//     为了以后好算,比如，你测量的一个宽度是100px,就可以写为1rem,以及1px=0.01rem等等*/
//   getRem(2560, 100)
// };
window.onresize = function () {
    getRem(2560, 100)
};

function getRem(pwidth, prem) {
    // var html = document.getElementsByTagName("html")[0];
    var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
    document.documentElement.style.fontSize = oWidth / pwidth * prem + "px";
}


useEffect(() => {
  setRemUnit(2560,100);
  window.addEventListener('resize', setRemUnit(2560,100));

  return () => {
    window.removeEventListener('resize', setRemUnit(2560,100));
  };
});

useEffect(()=>{
  if (location) {
      let path = location.pathname;
      netPath = path
      setNetPath(netPath)
  }
  console.log("netPath===>",netPath);
},[location])

const test = (
  width < 1366 ?
  {
    hashPriority: "high"
  }
  :
  {}
  // <StyleProvider >
  // </StyleProvider>
)

useEffect(()=>{
  if (netPath === '/') {
    setTimeout(()=>{
      setIsLoading(false)
    },3000)
  }else{
    setIsLoading(false)
  }
},[])

  return (
    <StyleProvider {...test} transformers={[legacyLogicalPropertiesTransformer]} >
      <div className="App">
          {
            isLoading ? <div className='page-mask'>
                <img src={require('./assets/images/img/isLoading.gif')} alt='' />
            </div>
            :
            <Layout>
                <Header className={`Header ${netPath === "/" && 'IndexHeader'} ${width < 481 && "HeaderMobile"}`}>
                    <AppHeader />
                </Header>
                <Content>
                    {outlet}
                </Content>
                <Footer className={`Footer ${width < 481 && "FooterMobile"}`}>
                  <AppFooter />
                </Footer>
            </Layout>
          }
          
          
      </div>
    </StyleProvider>
  );
}

export default App;
