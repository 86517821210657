

export default function EquipmentAndDetail (props) {

    const { iconList, detailInfo } = props

    return <div className="EquipmentAndDetail">
        <div className="icon-list">
            {
                iconList.map((e,i)=>(
                    <div className={`list-item ${e.isSelected && 'list-item-active'}`} key={i} >
                        {
                            e.isSelected ? <img className="icon" src={require('../../assets/images/property/'+e.activeIcon)} /> : <img className="icon" src={require('../../assets/images/property/'+e.icon)} />
                        }
                        <div className="text">{e.name}</div>
                    </div>
                ))
            }
        </div>
        <div className="text-list">
            <div className="list-item">
                <div className="item-title">設備</div>
                <div className="item-data">{detailInfo.equipment}</div>
            </div>
            <div className="list-item">
                <div className="item-title">契約期間</div>
                <div className="item-data">{detailInfo.contract}</div>
            </div>
            <div className="list-item">
                <div className="item-title">現況</div>
                <div className="item-data">{detailInfo.statusQuo}</div>
            </div>
            <div className="list-item">
                <div className="item-title">引渡し</div>
                <div className="item-data">{detailInfo.extradition}</div>
            </div>
        </div>
    </div>
}

