import { useViewport } from "../hooks/useViewport"


export default function AppFooter () {
    const { width } = useViewport()

    return <div className="AppFooter">
        <div className="statement">
            <p>copyright©ReBITA All Rights Reserved</p>
        </div>
    </div>
}


