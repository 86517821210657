import serviceAxios from "..";

// 获取物业详情
export const getPropertyInfo = (data) => {
    const {id} = data
    return serviceAxios({
        url: `/estate/${id}`,
        method: 'get'
    })
}

// 获取物业列表
export const getPropertyList = () => {

    return serviceAxios({
        url: `/estate/`,
        method: "get"
    })
}

// 获取房屋信息
export const getRoomInfo = (data) => {
    const { id } = data;
    return serviceAxios({
        url: `/suite/all?estateID=${id}`,
        method: 'get'
    })
}

// 发送预约信息
export const postRemark = (data) => {

    return serviceAxios({
        url: "/appointment/create",
        method: 'post',
        data
    })
}

